<template>
  <div class="Sidebar">
    <mark v-if="mode === 'development'" class="absolute">개발모드</mark>
    <div class="Sidebar__Logo">
      <Logo></Logo>
    </div>
    <div class="Sidebar__subTitle">PLATFORM<b>MANAGER</b></div>
    <div class="Sidebar__menulist">
<!--      <router-link to="/app/dashboard" class="Sidebar__menuItem">-->
<!--        <DanshboardIcon></DanshboardIcon>-->
<!--        <span>대시보드</span>-->
<!--      </router-link>-->
      <router-link to="/app/sales" class="Sidebar__menuItem">
        <SalesIcon></SalesIcon>
        <span>판매현황</span>
      </router-link>
      <router-link to="/app/product" class="Sidebar__menuItem">
        <ProductIcon></ProductIcon> <span>상품관리</span>
      </router-link>
      <router-link to="/app/settlement" class="Sidebar__menuItem">
        <SettlementIcon></SettlementIcon>
        <span>정산관리</span>
      </router-link>
      <router-link to="/app/qna" class="Sidebar__menuItem">
        <QnaIcon></QnaIcon>
        <span>1:1 문의관리</span>
      </router-link>
      <router-link to="/app/member" class="Sidebar__menuItem not-stroke">
        <MemberIcon></MemberIcon>
        <span>회원관리</span>
      </router-link>
      <router-link to="/app/registerToken" class="Sidebar__menuItem not-stroke">
        <MemberIcon></MemberIcon>
        <span>레지스터 관리</span>
      </router-link>
      <router-link to="/app/event" class="Sidebar__menuItem not-stroke">
        <EventIcon></EventIcon>
        <span>이벤트 관리</span>
      </router-link>
      <router-link to="/app/banner" class="Sidebar__menuItem not-stroke">
        <BannerIcon></BannerIcon>
        <span>홈배너관리</span>
      </router-link>
      <router-link to="/app/featuredSeller" class="Sidebar__menuItem not-stroke">
        <CrownIcon></CrownIcon>
        <span>피쳐드셀러관리</span>
      </router-link>
      <router-link to="/app/lotInside" class="Sidebar__menuItem">
        <LotInsideIcon></LotInsideIcon>
        <span>LOT Inside관리</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import Logo from './Logo.vue';
// import DanshboardIcon from '@/views/layouts/AppLayout/icons/DanshboardIcon.vue';
import SalesIcon from '@/views/layouts/AppLayout/icons/SalesIcon.vue';
import ProductIcon from '@/views/layouts/AppLayout/icons/ProductIcon.vue';
import SettlementIcon from '@/views/layouts/AppLayout/icons/SettlementIcon.vue';
import QnaIcon from '@/views/layouts/AppLayout/icons/QnaIcon.vue';
import BannerIcon from '@/views/layouts/AppLayout/icons/BannerIcon.vue';
import CrownIcon from '@/views/layouts/AppLayout/icons/CrownIcon.vue';
import MemberIcon from '@/views/layouts/AppLayout/icons/MemberIcon.vue';
import LotInsideIcon from '@/views/layouts/AppLayout/icons/LotInsideIcon.vue';
import EventIcon from '@/views/layouts/AppLayout/icons/EventIcon.vue';

export default {
  components: {
    Logo,
    // DanshboardIcon,
    SalesIcon,
    ProductIcon,
    SettlementIcon,
    QnaIcon,
    BannerIcon,
    CrownIcon,
    MemberIcon,
    LotInsideIcon,
    EventIcon
  },
  data: () => {
    return {};
  },
  setup() {
    const mode = computed(() => {
      return process.env.VUE_APP_ENV;
    });
    return {
      mode,
    };
  },
};
</script>

<style scoped lang="scss">
.Sidebar {
  width: 248px;
  min-height: 100vh;
  color: #bbbbbb;
  .Sidebar__menuItem {
    &:not(.not-stroke) {
      svg {
        stroke: #bbb;
      }
    }
  }
}

.Sidebar__Logo {
  height: 72px;
  padding-left: 26px;
  background-color: #000;
  display: flex;
  align-items: center;
}

.Sidebar__subTitle {
  margin-top: 32px;
  margin-bottom: 16px;
  color: #49cdda;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-left: 24px;
}

.Sidebar__menulist {
}

.Sidebar__menuItem {
  display: block;
  display: flex;
  align-items: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;

  &:hover,
  &.router-link-active {
    background-color: #666;
    color: #ffffff;
    font-weight: 600;
    &:not(.not-stroke) {
      svg {
        stroke: #ffffff;
      }
    }
  }


  & > span {
    margin-left: 10px;
  }
}
</style>
