<template>
  <router-view />
</template>

<style lang="scss">
.admin-button {
  &:hover {
    background: #E3FAFE;
  }
  &:focus {
    background: #171717;
    color: #ffffff;
  }
  &.text-white {
    &:hover {
      background: #171717;
    }
  }
}
.admin-input {
  &:focus {
    outline: none;
    border: 1px solid #49CDDA;
  }
}

.filter-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #D9D9D9;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
  &:checked {
    background-image: url('assets/input_check-primary.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
</style>
