<template>
  <div class="AppLayout">
    <div class="AppLayout__sidebar">
      <Sidebar></Sidebar>
    </div>
    <div class="AppLayout__main">
      <Topbar></Topbar>
      <div class="AppLayout_routeView">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import Topbar from './Topbar.vue';
export default {
  components: {
    Sidebar,
    Topbar,
  },
  data: () => {
    return {};
  },
};
</script>

<style scoped lang="scss">
.AppLayout {
  display: flex;
}

.AppLayout__sidebar {
  width: 248px;
  background: #171717;
}

.AppLayout__main {
  position: relative;
  flex: 1;
  background-color: #f5f6fb;
  &.bg-white {
    background: #fff;
  }
}

.AppLayout_routeView {
  width: 1672px;
  margin: auto;
  padding: 64px 56px;
}
</style>
